<template>
  <svg
    id="discord"
    class="social-icon logo-group"
    width="32"
    height="32"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="discordTitle"
    role="img"
  >
    <title id="discordTitle">Link to Balancer's Discord</title>
    <g fill="none" fill-rule="evenodd">
      <path
        class="bg"
        d="M15.981 0C7.167 0 .02 7.163.02 16c0 8.836 7.148 16 15.962 16 8.815 0 15.962-7.164 15.962-16 0-8.837-7.147-16-15.962-16z"
        fill="#FFF"
      />
      <path
        class="logo"
        d="M20.302 24.464l-.494-1.727L21 23.848l1.127 1.045 2.002 1.774V9.923A1.916 1.916 0 0022.219 8H9.744a1.916 1.916 0 00-1.91 1.923V22.54c0 1.064.857 1.923 1.91 1.923h10.559zm-1.676-4.275l-.615-.756c1.22-.345 1.685-1.11 1.685-1.11-.381.252-.745.429-1.07.55a6.118 6.118 0 01-1.35.402 6.508 6.508 0 01-2.412-.01 7.802 7.802 0 01-1.369-.401 5.45 5.45 0 01-.68-.317l-.037-.022-.01-.004-.037-.021-.037-.028-.26-.159s.446.747 1.629 1.102l-.624.774c-2.058-.065-2.84-1.418-2.84-1.418 0-3.006 1.341-5.442 1.341-5.442 1.34-1.008 2.617-.98 2.617-.98l.093.112c-1.676.486-2.45 1.223-2.45 1.223l.55-.27c.996-.44 1.788-.56 2.114-.589l.158-.018a7.577 7.577 0 014.684.877s-.736-.7-2.319-1.185l.13-.15s1.276-.028 2.617.98c0 0 1.34 2.436 1.34 5.442 0 0-.761 1.302-2.848 1.418zm-.932-4.358c-.53 0-.95.466-.95 1.036 0 .569.429 1.036.95 1.036.531 0 .95-.467.95-1.036 0-.57-.419-1.036-.95-1.036zm-3.398 0c-.531 0-.95.466-.95 1.036 0 .569.428 1.036.95 1.036.53 0 .95-.467.95-1.036.009-.57-.42-1.036-.95-1.036z"
        fill="#000"
      />
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style scoped>
.social-icon:hover .bg {
  fill: #7289da;
}
</style>

<script lang="ts" setup>
import { computed, defineAsyncComponent } from 'vue';

import useFathom from '@/composables/useFathom';
import useWeb3 from '@/services/web3/useWeb3';

// import step1 from './StepIcons/1.vue'

/**
 * TYPES
 */
type Props = {
  headline: string;
};

/**
 * PROPS & EMITS
 */
const props = withDefaults(defineProps<Props>(), {
  headline: '',
});

/**
 * COMPOSABLES
 */
const { isWalletReady, isWalletConnecting } = useWeb3();
const { trackGoal, Goals } = useFathom();

/**
 * COMPUTED
 */
const classes = computed(() => ({
  ['h-64']: !isWalletReady.value && !isWalletConnecting.value,
  ['h-44']: isWalletReady.value || isWalletConnecting.value,
}));
</script>

<template>
  <div class="container mx-auto">
    <!-- <component :is="stepIcon"/> -->
    <div class="mx-auto text-center headline my-[55px]">{{ headline }}</div>
  </div>
</template>

<style scoped>
.headline {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #fdfdfd;
}
</style>
import { Keys } from '../types';

const keys: Keys = {
  infura: '36bf772af0a340e3be39429d97753e80',
  alchemy: 'cQGZUiTLRCFsQS7kbRxPJK4eH4fTTu88',
  graph: '7c7b4c36244b0b86171a3931eaf9bb23',
  balancerApi: 'da2-7a3ukmnw7bexndpx5x522uafui',
};

export default keys;

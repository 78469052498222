import { Contracts } from '../types';

const contracts: Contracts = {
  merkleRedeem: '',
  merkleOrchard: '',
  merkleOrchardV2: '',
  multicall: '0x04364F8908BDCB4cc7EA881d0DE869398BA849C9',
  authorizer: '0x9F00e5b7a6795e5da0cD1c93bd8479Aa332AE48C',
  vault: '0x6985436a0E5247A3E1dc29cdA9e1D89C5b59e26b',
  weightedPoolFactory: '0x98b5584A0c40F2c50CBa0Adb40E51A8caD79b9AC',
  stablePoolFactory: '0xA408376E84DedD19616e8Cd79753eb4B594acd85',
  weth: '0xC9BdeEd33CD01541e1eeD10f90519d2C06Fe3feB',
  rETH: '',
  stMATIC: '',
  stETH: '',
  wstETH: '',
  lidoRelayer: '',
  balancerHelpers: '0x530D85776e560303D18094a366f127ff2F113526',
  batchRelayer: '0x28965a81eF4Fc8d236047122C458918532A28CA8',
  veBAL: '0xBe96730583b46140210efA92AC6039EF11247657',
  gaugeController: '0x31eCA9385Fa44e0f8D83322F79937943216Df7c5',
  gaugeFactory: '0x80C296469a1ffFfB6547A8570927864708b86303',
  balancerMinter: '0xfc5803Ba89e46D37Bbac542B7449b4c1c0628587',
  tokenAdmin: '0x0e9f2A65D77a40562b02000c01f6089D20FE37d6',
  veDelegationProxy: '0xDA4765fE5AfAEac3A0582eDAF78E76b6C4cF9a7C',
  veBALHelpers: '0x4a0A3ec68BF26bF0446fA4664EC90a25E521c0f0',
  feeDistributor: '0xd30b72Ff398DE0fF24934fFdb49B28D738076302',
  feeDistributorDeprecated: '',
  faucet: '',
  NEAR: '0xC42C30aC6Cc15faC9bD938618BcaA1a1FaE8501d',
  pNEAR: '0x990e50E781004EA75e2bA3A67eB69c0B1cD6e3A6',
  STNEAR: '0x07F9F7f963C5cD2BBFFd30CcfB964Be114332E30',
  pSTNEAR: '0xFbE0Ec68483c0B0a9D4bCea3CCf33922225B8465',
};

export default contracts;

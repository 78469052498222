import { Contracts } from '../types';

const contracts: Contracts = {
  merkleRedeem: '',
  merkleOrchard: '',
  merkleOrchardV2: '',
  multicall: '0x075A47CDF38Bc67A018A4742a2Fe7e9053d41C8c',
  authorizer: '0x6060A8adF2016FD70c05099966C71F235e289582',
  vault: '0x9Ced3B4E4DC978265484d1F1f569010E13f911c9',
  weightedPoolFactory: '0x981071AA1A430fC1b54C3E171608f88975497cF3',
  stablePoolFactory: '',
  weth: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
  stMATIC: '',
  rETH: '',
  stETH: '',
  wstETH: '',
  lidoRelayer: '',
  balancerHelpers: '0xf0f3b9Eee32b1F490A4b8720cf6F005d4aE9eA86',
  batchRelayer: '0x15F707033cA3a44FB3deC7EBC2F3ac5DB3b4e099',
  gaugeFactory: '0xA5dF6D8D59A7fBDb8a11E23FDa9d11c4103dc49f',
  balancerMinter: '0xA5dF6D8D59A7fBDb8a11E23FDa9d11c4103dc49f',
  gaugeController: '',
  tokenAdmin: '',
  veBAL: '',
  veDelegationProxy: '',
  veBALHelpers: '0xf0f3b9Eee32b1F490A4b8720cf6F005d4aE9eA86',
  feeDistributor: '',
  feeDistributorDeprecated: '',
  faucet: '',
  gaugeRewardsHelper: '0x5749c1374cc9DE28B94cf054dEb303D4cA3464bF',
};

export default contracts;

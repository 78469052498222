<template>
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.25" width="16" height="16" rx="8" fill="#BDB2DD"/>
    <g clip-path="url(#clip0_820_3866)">
    <path d="M6.25 5.5C6.25 5.10218 6.43437 4.72064 6.76256 4.43934C7.09075 4.15804 7.53587 4 8 4H8.5C8.96413 4 9.40925 4.15804 9.73744 4.43934C10.0656 4.72064 10.25 5.10218 10.25 5.5C10.2684 5.82463 10.1808 6.14645 10.0005 6.41699C9.82012 6.68753 9.55674 6.89214 9.25 7C8.94326 7.14382 8.67988 7.41663 8.49952 7.77735C8.31916 8.13807 8.23159 8.56716 8.25 9" stroke="#150128" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.25 12V12.005" stroke="#150128" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_820_3866">
    <rect width="12" height="12" fill="white" transform="translate(2.25 2)"/>
    </clipPath>
    </defs>
    </svg>
</template>
<template>
  <svg
    id="medium"
    class="social-icon"
    width="32"
    height="32"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="mediumTitle"
    role="img"
  >
    <title id="mediumTitle">Link to Balancer's blog on Medium</title>
    <g fill="none" fill-rule="evenodd">
      <g fill-rule="nonzero">
        <path
          class="bg"
          d="M16 0C7.185 0 .038 7.164.038 16S7.185 32 16 32s15.962-7.164 15.962-16S24.815 0 16 0z"
          fill="#FFF"
        />
        <path
          class="logo"
          d="M11.826 10.4c2.984 0 5.403 2.507 5.403 5.6 0 3.093-2.419 5.6-5.403 5.6-2.984 0-5.403-2.507-5.403-5.6 0-3.094 2.419-5.6 5.403-5.6zm8.624.328c1.492 0 2.702 2.361 2.702 5.272s-1.21 5.271-2.702 5.271-2.702-2.36-2.702-5.271c0-2.91 1.21-5.272 2.702-5.272zm4.17.549c.526 0 .95 2.115.95 4.723 0 2.608-.425 4.723-.95 4.723-.524 0-.95-2.115-.95-4.723 0-2.608.426-4.723.95-4.723z"
          fill="#000"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style scoped>
.social-icon:hover .bg {
  fill: rgb(26 137 23 / 100%);
}
</style>

<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="16" height="16" rx="8" fill="#0CE6B5"/>
<g clip-path="url(#clip0_803_3892)">
<path d="M4.5 8L7 10.5L12 5.5" stroke="#150128" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_803_3892">
<rect width="12" height="12" fill="white" transform="translate(2 2)"/>
</clipPath>
</defs>
</svg>

</template>
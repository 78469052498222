<template>
  <svg
    id="github"
    class="social-icon"
    width="32"
    height="32"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="githubTitle"
    role="img"
  >
    <title id="githubTitle">Link to Balancer Lab's Github</title>
    <g fill="none">
      <path
        class="bg"
        d="M16.019 0C7.204 0 .057 7.164.057 16s7.147 16 15.962 16S31.98 24.836 31.98 16 24.834 0 16.02 0z"
        fill="#FFF"
      />
      <path
        class="logo"
        d="M18.97 24.821c-.472.092-.623-.198-.623-.448v-2.561c0-.87-.305-1.439-.64-1.728 2.075-.23 4.254-1.02 4.254-4.605 0-1.02-.363-1.852-.96-2.506.097-.236.416-1.185-.091-2.47 0 0-.78-.251-2.558.956a9.024 9.024 0 00-2.333-.312 8.95 8.95 0 00-2.332.313c-1.778-1.207-2.56-.956-2.56-.956-.505 1.285-.187 2.235-.09 2.47a3.614 3.614 0 00-.96 2.505c0 3.576 2.173 4.376 4.242 4.612-.266.233-.507.644-.59 1.248-.533.238-1.88.65-2.71-.776 0 0-.492-.896-1.428-.963 0 0-.908-.012-.063.568 0 0 .611.287 1.033 1.365 0 0 .539 1.667 3.13 1.102v1.737c0 .247-.15.536-.615.45a9.332 9.332 0 01-6.368-8.855c0-5.156 4.17-9.334 9.31-9.334 5.142 0 9.312 4.178 9.312 9.334a9.332 9.332 0 01-6.36 8.854z"
        fill="#000"
      />
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style scoped>
.social-icon:hover .bg {
  fill: #444;
}
</style>

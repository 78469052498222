<template>
  <svg
    id="twitter"
    class="social-icon"
    width="32"
    height="32"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="logoTitle"
    role="img"
  >
    <title id="twitterTitle">Link to Balancer's Twitter</title>
    <g fill-rule="nonzero" fill="none">
      <path
        class="bg"
        d="M15.962 0C7.147 0 0 7.164 0 16s7.147 16 15.962 16 15.963-7.164 15.963-16S24.777 0 15.962 0z"
        fill="#FFF"
      />
      <path
        class="logo"
        d="M24.031 12.86c.244 5.387-3.764 11.392-10.86 11.392-2.157 0-4.164-.635-5.855-1.721a7.673 7.673 0 005.656-1.586 3.83 3.83 0 01-3.57-2.66c.6.115 1.19.082 1.726-.065-1.837-.37-3.106-2.03-3.064-3.804a3.805 3.805 0 001.73.479A3.84 3.84 0 018.612 9.78a10.836 10.836 0 007.874 4.002c-.557-2.395 1.256-4.703 3.724-4.703 1.097 0 2.09.465 2.788 1.21a7.61 7.61 0 002.426-.93 3.844 3.844 0 01-1.68 2.119 7.608 7.608 0 002.193-.604 7.679 7.679 0 01-1.906 1.985z"
        fill="#000"
      />
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style scoped>
.social-icon:hover .bg {
  fill: rgb(29 161 242 / 100%);
}
</style>

<template>
  <svg
    id="linkedin"
    class="social-icon"
    width="32"
    height="32"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="linkedinTitle"
    role="img"
  >
    <title id="linkedinTitle">Link to Balancer's LinkedIn profile</title>
    <g fill-rule="nonzero" fill="none">
      <path
        class="bg"
        d="M16 0C7.163 0 0 7.164 0 16s7.163 16 16 16c8.836 0 16-7.163 16-16 0-8.836-7.164-16-16-16z"
      />
      <path
        class="logo"
        d="M11.307 23.059H7.622V11.943h3.685V23.06zM9.464 10.425h-.023c-1.237 0-2.037-.854-2.037-1.92 0-1.09.824-1.92 2.085-1.92 1.26 0 2.037.83 2.06 1.92 0 1.066-.8 1.92-2.085 1.92zM24.596 23.06H20.91V17.11c0-1.493-.533-2.513-1.868-2.513-1.017 0-1.623.687-1.89 1.351-.097.238-.121.57-.121.902v6.208h-3.685s.048-10.073 0-11.116h3.685v1.574c.489-.756 1.365-1.835 3.32-1.835 2.426 0 4.244 1.589 4.244 5.003v6.374z"
      />
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style scoped>
.social-icon:hover .bg {
  fill: #0a66c2;
}
</style>

<template>
  <svg
    id="mail"
    class="social-icon"
    width="32"
    height="32"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="mailTitle"
    role="img"
  >
    <title id="mailTitle">Link to Balancer Lab's contact email address</title>
    <g fill-rule="nonzero" fill="none">
      <path
        class="bg"
        d="M16.038 0C7.223 0 .075 7.164.075 16s7.148 16 15.963 16S32 24.836 32 16 24.853 0 16.038 0z"
      />
      <path
        class="logo"
        d="M7.347 11.612l8.69 7.06 8.691-7.06v10.584H7.348V11.612zm17.369-1.86l-8.678 7.05-8.68-7.05h17.358z"
      />
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style scoped>
.social-icon:hover .bg {
  fill: #ffc017;
}
</style>

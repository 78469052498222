<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_755_6248)">
<path d="M5 12H19" stroke="#BDB2DD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5 12L11 18" stroke="#BDB2DD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M5 12L11 6" stroke="#BDB2DD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_755_6248">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>

</template>
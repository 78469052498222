export const ROUTE_META_DATA = {
  home: {
    metaTitle: 'Polaris DEX LPs',
    metaDescription:
      'Explore DeFi liquidity pools or create your own. Provide liquidity to accumulate yield from swap fees while retaining your token exposure as prices move',
    metaKeywords: '',
  },
  pool: {
    metaTitle: '[pool_symbol]',
    metaDescription: '[pool_name] — a Polaris Finance [pool_type] pool',
  },
  swap: {
    metaTitle: 'Swap your tokens on Aurora',
    metaDescription:
      'Swap ERC-20 tokens using Polaris Finance liquidity or with CoW protocol for gas-less signature trades, MEV protection and the best prices via intelligent routing across DeFi exchanges',
  },
  claim: {
    metaTitle: 'Claim liquidity mining incentives from staking',
    metaDescription:
      'LPs who stake can claim liquidity mining incentives. veXPOLAR holders can boost incentives and claim their share of protocol revenue',
  },
  portfolio: {
    metaTitle: 'Your Polaris DEX Portfolio',
    metaDescription:
      'Track your Polaris Finance Portfolio, including your unstaked and stake LP positions',
  },
  vexpolar: {
    metaTitle: 'Lock and Vote for Polaris DEX Gauges',
    metaDescription:
      'Get veXPOLAR (voter escrowed 20pNEAR-40XPOLAR-40SPOLAR) for extra liquidity mining incentives, governance voting power and a share of protocol revenue',
  },
  'terms-of-use': {
    metaTitle: 'Polaris Finance Terms of Use',
    metaDescription: 'The rules for the use of the website',
  },
  'privacy-policy': {
    metaTitle: 'Polaris Finance Privacy Policy',
    metaDescription:
      'How this website collects, uses and shares personal information',
  },
  'cookies-policy': {
    metaTitle: 'Polaris Finance Cookie Policy',
    metaDescription: 'How this website uses browser cookies',
  },
  risks: {
    metaTitle: 'Polaris Finance Protocol Risks',
    metaDescription: 'The risks of using the Balancer Protocol',
  },
  sunrises: {
    metaTitle: 'Polaris DEX Sunrise',
    metaDescription: 'The Polaris Finance Sunrises',
  },
  bonds: {
    metaTitle: 'Polaris DEX Bond',
    metaDescription: 'The Polaris Finance Bonds',
  },
  airdrop: {
    metaTitle: 'Claim your Airdrop',
    metaDescription: 'The Polaris Finance Airdrop',
  },
};

<template>
  <svg
    id="youtube"
    class="social-icon"
    role="img"
    aria-labelledby="youtubeTitle"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title id="youtubeTitle">Link to Balancer's Youtube page</title>
    <path
      d="M16 0C7.164 0 0 7.164 0 16C0 24.836 7.164 32 16 32C24.836 32 32 24.836 32 16C32 7.164 24.836 0 16 0Z"
      class="bg"
      fill="#FFF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.8253 9.42833C24.682 9.65898 25.3574 10.3344 25.5881 11.1911C26.0164 12.7562 25.9999 16.0181 25.9999 16.0181C25.9999 16.0181 25.9999 19.2635 25.5881 20.8286C25.3574 21.6853 24.682 22.3607 23.8253 22.5914C22.2602 23.0032 16 23.0032 16 23.0032C16 23.0032 9.75616 23.0032 8.17462 22.5749C7.31795 22.3443 6.6425 21.6688 6.41186 20.8121C6 19.2635 6 16.0016 6 16.0016C6 16.0016 6 12.7562 6.41186 11.1911C6.6425 10.3344 7.33443 9.6425 8.17462 9.41186C9.73969 9 16 9 16 9C16 9 22.2602 9 23.8253 9.42833ZM19.2127 16.0016L14.0068 19V13.0033L19.2127 16.0016Z"
      class="logo"
      fill="#000"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style scoped>
.social-icon:hover .bg {
  fill: #f00;
}
</style>
